import { graphql, Link } from 'gatsby'
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'
import PropTypes from 'prop-types'
import React from 'react'
import Layout from '../components/Layout'

export const SiteMapPageTemplate = ({
  pages
}) => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0)
    window.scrollTo(0, 1)
  }
  return (
    <div className="container">
      <div className="columns is-multiline" style={{marginTop:100}}>
        {pages &&
          pages.map(({ node: post }, i) => {
            return (
              <div className="is-parent column is-4" key={post.id}>
                <article >
                  <header>
                    <p className="post-meta">
                      <Link
                        to={post.fields.slug}
                      >
                        &gt;  {post.frontmatter.title}
                      </Link>
                    </p>
                  </header>
                </article>
              </div>
            )
          }
          )}
      </div>
    </div>

  )
}

SiteMapPageTemplate.propTypes = {
  pages: PropTypes.array
}

const SiteMapPage = ({
  data,
}) => {
  const pages = data.allMarkdownRemark.edges
  return (
    <Layout>
      <SiteMapPageTemplate
        pages={pages}
      />
    </Layout>
  )
}

SiteMapPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default SiteMapPage

export const pageQuery = graphql`
  query SiteMapPageTemplate {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 120)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }

  }
`
